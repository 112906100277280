.bg-gray {
    background: #e6e6e6;
}

.intro-video {
    height: 0;
    overflow: hidden;
    padding-bottom: 55.25%;
    padding-top: 30px;
    position: relative;

    iframe {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

.mobile-country-selector {
    background: #e6e6e6;
    padding: 30px 15px;
    position: relative;
    z-index: 2;

    &__title {
        color: #111;
        font-size: 20px;
        font-weight: 300;
        margin: 0;
        text-align: center;
    }

    .mobile-country-selector__select-wrapper {
        margin: 15px auto 0;
        max-width: 345px;
        position: relative;
        width: 100%;
    }

    .mobile-country-selector__select {
        background: #fff;
        border: 1px solid #111;
        color: #666;
        height: 50px;
        padding-left: 10px;
        padding-top: 13px;
        width: 100%;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    .mobile-country-selector__dropdown {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        width: 10px;
    }

    @media (min-width: 992px) {
        display: none;
    }

    .invisible {
        opacity: 0;
        visibility: hidden;
    }
}
