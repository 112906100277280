.header {

}

.header__top {
    background-image: linear-gradient(99deg, #fdab1c, #e42730);
    height: 20px;
    width: 100%;
}

.header__logo {
    background: #fff;
    padding: 15px 0;
    position: relative;
    text-align: center;

    &:after {
        border-radius: 5px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }
}

.header__logo__link {
    display: inline-block;
    padding: 13px 20px 11px;
}

.header__logo__image {
    max-width: 200px;
}
