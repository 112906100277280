.map {
    height: 530px;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 1;


    svg {
        left: 50%;
        position: absolute;
        top: 0;
        transform: translateX(-50%);
        z-index: 1;

        .country,
        .marker {
            opacity: 1;
            transition: .4s ease-out;
            visibility: visible;
        }

        .country {
            fill: #444;
        }

        .country-link {
            &:not(.no-link) {
                cursor: pointer;
            }

            &:hover {
                .country {
                    fill: url(#MyGradient);
                }

                .country-text {
                    opacity: 1;
                    visibility: visible;
                }

                .marker {
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }

        .country-text {
            font-size: 8px;
            font-weight: 700;
            letter-spacing: 0.07em;
            opacity: 0;
            text-transform: uppercase;
            transition: .4s ease-out;
            visibility: hidden;
            font-family: $font-family-open-sans;
            fill: $color-white;
        }
    }
}

.map-container {
    bottom: 60px;
    position: absolute;
    width: 100%;

    @media (max-width: 991px) {
        bottom: 0;
    }

    @media (min-width: 992px) {
        display: none;
    }
}

.map-label {
    background: #fff;
    width: 315px;

    ul {
        align-items: center;
        background: #fff;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .2);
        display: flex;
        height: 40px;
        justify-content: space-around;
        margin: 0;
        padding: 0;
        position: relative;
        z-index: 2;

        li {
            display: inline-block;
            list-style-type: none;
            padding-left: 25px;
            position: relative;

            &:before {
                border-radius: 50%;
                content: "";
                height: 13px;
                left: 5px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 13px;
            }

            &:nth-child(1) {
                &:before {
                    background: #E42730;
                }
            }

            &:nth-child(2) {
                &:before {
                    background: #fdab1c;
                }
            }
        }
    }
}

.map {
    @media (max-width: 991px) {
        height: 450px;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .2);

        svg {
            height: 390px;
        }

        .map-label {
            height: 60px;
            bottom: 0;
            width: 375px;
            left: 0;
            margin: auto;
            position: absolute;
            right: 0;

            ul {
                height: 60px;
                box-shadow: none;
            }
        }
    }

    @media (max-width: 767px) {
        height: 420px;

        svg {
            height: 360px;
        }

        .map-label {
            width: 315px;
            height: 60px;
        }
    }

    @media (max-width: 599px) {

        svg {
            transform: translateX(-51%);
        }
    }

    @media (max-width: 480px) {
        height: 340px;

        svg {
            height: 280px;
        }
    }
}
