* {
    box-sizing: border-box;
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

html {
}

body {
    color: #111;
    font: 400 14px/1.6 $font-family-open-sans, sans-serif;
    transition: all .4s .3s ease-in;
}

body, html {
    min-height: 100vh;
}

.container {
    margin: 0 auto;

    &:after,
    &:before {
        box-sizing: border-box;
        content: " ";
        display: table;
    }

    &:after {
        clear: both;
    }
}

a,
.link {
    &:hover {
        cursor: pointer;
    }
}

p {
    line-height: 1.8;
}
