// Hero section

.hero {
  background-image: url('../../static/img/performance-statements-hero.jpg');
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 991px) {
    padding: 50px 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 70px 0;
  }

  @media (min-width: 1200px) {
    padding: 92px 0;
  }


  &__title {
    margin: 0 auto 20px;
    text-align: center;
    color: #fff;
    font-weight: bold;
    letter-spacing: 1.3px;

    @media (max-width: 991px) {
      font-size: 24px;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 40px;
    }

    @media (min-width: 1200px) {
      font-size: 60px;
    }
  }

  &__language-selector {

    &__wrapper {
      padding-bottom: 50px;
    }

    &__title {
      margin: 0 auto 20px;
      font-size: 16px;
      text-align: center;
      color: #fff;
    }

    &__dropdown-wrapper {
      width: 100%;
      max-width: 240px;
      margin: 0 auto;
      border: solid 1px #111;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
      color: #666;

      &:not(.opened) {
        .hero__language-selector__dropdown-item:not(.selected) {
          display: none;
        }
      }
    }

    &__dropdown-item {
      height: 50px;
      padding: 10px;
      background-color: #fff;
      display: flex;
      align-items: center;
      gap: 10px;

      &:hover {
        background-color: darken(#fff, 5%);
      }

      .hero__language-selector__dropdown-wrapper.opened & {
        &.selected {
          background-color: #fdab1c;
        }
      }
    }

    &__dropdown-flag {
      padding: 15px;
      background-position: center;
      background-repeat: no-repeat;
    }

    &__dropdown-arrow {
      width: 10px;
      position: absolute;
      right: 15px;
      top: 25px;
      transform: translateY(-50%);
    }
  }
}


// Download document section

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.documents-wrapper {
  max-width: 1170px;
  padding: 80px 15px 122px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 40px;
}

.document-wrapper {
  color: #444;
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }

  @media (min-width: 768px) {
    flex: 0 1 calc(50% - 15px);
    max-width: calc(50% - 15px);
  }
}

.document {
  &__product-family {
    padding-bottom: 13px;
    margin: 0 0 25px;
    font-family: $font-family-montserrat;
    font-size: 22px;
    border-bottom: solid 5px #e6e6e6;
  }

  &__details-wrapper {
    height: 61px;
    padding-left: 8px;
    display: flex;
    align-items: center;
  }

  &__image-link {
    height: 100%;
  }

  &__image-pictogram {
    height: 100%;
  }

  &__download-wrapper {
    padding-left: 37px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__download-doctype {
    margin: 0;
    font-family: $font-family-open-sans;
    font-size: 20px;
  }

  &__download-link {
    font-family: $font-family-montserrat;
    font-size: 11px;
    letter-spacing: 0.92px;
    color: #e42730;
  }
}
