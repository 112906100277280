.cta {
    @media (min-width: 992px) {
        padding: 50px 15px;
    }
}

.cta__items {
    display: flex;
    padding: 50px 0;
}

.cta__item {
    align-items: center;
    display: flex;
    flex: 1;
    padding-left: 20px;
    position: relative;

    .video & {
        height: 100%;

        img {
            @media (max-width: 991px) {
                width: 100%;
            }
        }
    }

    &:before {
        background: #e6e6e6;
        content: "";
        height: 225px;
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 5px;


        .video & {
            height: 100%;
            background: #ffffff;
        }
    }

    &.cta__item--md-reverse {
        @media (max-width: 991px) {
            justify-content: center;
        }

        @media (min-width: 992px) {
            justify-content: flex-end;
            flex-direction: row-reverse;
        }
    }

    &.cta__item--right {
        position: relative;
        align-items: center;
        display: flex;
        flex: 1;

        @media (max-width: 991px) {
            padding-left: 20px;
            padding-bottom: 140px;
            margin: 60px 0 10px 0;
            display: block;
        }

        @media (min-width: 992px) {
            height: 230px;
            padding-left: 30px;
            float: right;
            justify-content: flex-end;

            &:before {
                display: none;
            }

            &:after {
                background: #e6e6e6;
                content: "";
                height: 225px;
                right: 0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 5px;
            }
        }

        .cta__item__image {
            @media (max-width: 991px) {
                padding-bottom: 10px;
                margin-bottom: 10px;
            }
            @media(min-width: 992px) {
                float: right;
            }
        }
    }
}

.cta__item__flex-item {
    flex: 1;

    &.cta__item__content {
        @media (min-width: 992px) {
            flex: 0 0 30%;
            margin-right: 30px;
        }

        .video & {
            @media (min-width: 992px) {
                flex: 168px 0 0;
                padding-right: 30px;
            }
        }
    }

    &.desktop-right {
        @media (min-width: 992px) {
            margin: 0 0 0 30px;
        }
    }

    .video & {
        display: flex;
        align-content: flex-start;
    }

    &.video-description {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-self: baseline;
    }
}

.cta__item__title {
    font-family: $font-family-montserrat;
    font-size: 22px;
    font-weight: 800;
    margin: 0;
}

.cta__item__text {
    color: #666;
    @media (max-width: 991px) {
        margin: 0 auto;
    }

    @media (min-width: 992px) {
        margin: 10px 0 0;
    }
}

.cta__item__image__wrapper {
    max-height: 100%;
    max-width: 100%;

    a {
        display: inline-block;
    }

    .video & {
        display: flex;
        justify-content: flex-end;
        align-items: baseline;
    }
}

.cta__item__image {
    max-width: 100%;

    .cta__item__image__wrapper--landscape & {
        max-height: 200px;

        .video & {
            max-height: 340px;
        }
    }

    .cta__item__image__wrapper--portrait & {
        max-height: 250px;

        @media (max-width: 991px) {
            transform: translateX(15px);
        }
    }
}

.cta__item__brochure {
    flex-direction: row-reverse;
    padding: 0 100px 0 20px;

    @media (min-width: 992px) and (max-width: 1199px) {
        padding: 0 20px;
    }
}

.cta__item__button {
    color: #fff;
    display: inline-block;
    font-family: $font-family-montserrat;
    font-size: 11px;
    font-weight: 800;
    letter-spacing: .09em;
    line-height: 1.4em;
    margin-top: 65px;
    padding: 13px 45px 12px 15px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;

    svg {
        height: 18px;
        margin-left: 10px;
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        width: 18px;
    }

    &.cta__item__button--download {
        background: #fdab1c;

        &:hover,
        &:active,
        &:focus {
            background: darken(#fdab1c, 5%);
        }


        &.blue {
            background: #5883cd;
        }
    }

    &.cta__item__button--video {
        background: #E42730;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;

        @media (min-width: 992px) {
            display: none;
        }

        &:hover,
        &:active,
        &:focus {
            background: darken(#E42730, 5%);
        }

    }
}

.cta__slogen__mobile {
    display: none;
}

@media (max-width: 991px) {
    .cta__items {
        display: block;
        padding: 0;
    }

    .cta__slogen__mobile {
        display: block;
        font-size: 30px;
        font-weight: 300;
        text-align: center;
        margin: 30px auto;
        padding: 0 25px;
    }

    .cta__item {
        height: auto;
        display: block;
        padding: 0 15px;
        text-align: center;

        &:before {
            display: none;

            .video & {
                height: 100%;
            }
        }


        &:not(:first-child) {
            margin-top: 80px;
        }
    }

    .cta__item__image__wrapper {
        margin: auto;
    }

    .cta__item__button {
        margin-top: 25px;
    }

    .cta__item__title {
        text-align: center;
        margin: 0;
    }
}

section {
    &.video {
        padding: 10px 0 0 0;

        @media (max-width: 991px) {
            padding: 70px 0;
        }
    }
}

#video-player-1 {
    position: relative;
}

.play-button {
    width: 70px;
    height: 70px;
    position: absolute;
    top: calc(50% - 35px);
    left: calc(50% - 35px);
    z-index: 900;

    &:hover {
        opacity: .75;
        transition: .3s ease-in-out;
    }

    .play-bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 50%;
        z-index: 901;
    }

    .play-image {
        height: 20px;
        position: absolute;
        top: calc(50% - 10px);
        left: calc(50% - 6px);
        z-index: 902;

        @media (max-width: 991px) {
            left: calc(50% - 32px);
        }
    }
}
