.footer {
  background: #444;
  padding: 50px 0;

  @media (max-width: 991px) {
    padding: 15px 15px;
  }
}

.footer__inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.footer__slogen {
  font-size: 40px;
  color: #d8d8d8;
  font-weight: 300;
  text-align: center;
  margin: 0;
  padding-bottom: 65px;
  line-height: normal;

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 36px;
  }

  @media (max-width: 991px) {
    display: none;
  }
}

.footer__contact__list {
  margin: 0;
  padding: 0;
  flex: 0 0 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  overflow: hidden;

  @media (max-width: 991px) {
    flex: 0 0 100%;
    width: 100%;
    flex-wrap: wrap;
    position: relative;
    max-width: 385px;
    margin: 0 auto;
  }
}

.footer__contact__list__item {
  list-style-type: none;
  text-align: center;
  flex: 0 0 195px;
  height: 120px;

  &:nth-of-type(1),
  &:nth-of-type(2) {
    @media (max-width: 991px) {
      padding-top: 15px;
    }
  }

  @media (max-width: 991px) {
    flex: 0 0 50%;
    width: 50%;
    height: 150px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 30px;
  }
}

.footer__contact__list__item__icon__wrapper {
  border-radius: 50%;
  width: 46px;
  height: 46px;
  border: 2px solid #111;
  position: relative;
  margin: 0 auto;

  svg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto
  }
}

.footer__contact__list__item__detail {
  color: $color-white;
  text-decoration: none;
  margin-top: 15px;
  display: inline-block;

  &:hover {
    text-decoration: none;
  }

  @media (max-width: 400px) {
    span {
      display: none;
    }
  }
}
